import React, { useEffect, useState } from 'react';
import { GImage } from '../g-image';
import { useQuery } from '@apollo/client';
import { GET_BLOGPOST_QUERY } from '@/Lib/graphql/queries/blog.query';
import type { Blog, BlogData, SegmentPageBlogData } from '@/Lib/types/blog';

type Props = {
  //tags?: string[];
  data: BlogData | SegmentPageBlogData;
};

const Blog = ({ data }: Props) => {
  const bData = {
    posts: 'posts' in data ? data.posts : data.segmentPagePosts,
  }

  const [additionalCount, setAdditionalCount] = useState<number>(0);
  const [blogs, setBlogs] = useState<Blog[]>(bData.posts || []);

  const {
    loading: additionalLoading,
    data: additionalData,
    error: additionalError,
  } = useQuery<{
    posts: Blog[];
  }>(GET_BLOGPOST_QUERY, {
    variables: {
      limit: additionalCount,
    },
  });

  useEffect(() => {
    console.log('bData', bData);
    if (bData && bData.posts.length < 3) {
      setAdditionalCount(3 - bData.posts.length);
    }
  }, [bData]);

  useEffect(() => {
    if (additionalData && additionalCount) {
      setBlogs([...bData.posts, ...additionalData.posts]);
    } else {
      setBlogs(bData.posts);
    }
  }, [additionalCount, bData, additionalLoading, additionalData]);

  return (
    <>
      {blogs
        ? blogs?.map((blog, index) => (
            <article
              key={index}
              className="flex flex-col group cursor-pointer"
              onClick={() => window.open(blog.url, '_blank')}
            >
              <div className="rounded overflow-hidden">
                <div className="relative h-56">
                  <GImage
                    path={blog.feature_image}
                    transformation="trip-cards"
                    alt={blog.title}
                    height="100%"
                    width="100%"
                    classes="rounded transition-shadow duration-300 ease-in "
                    hasLoadingBackground
                  />
                  <div className="absolute inset-0 bg-dark-900 opacity-0 group-hover:opacity-20 transition-opacity"></div>
                </div>
              </div>
              <h3 className="text-dark-800 text-xl mt-6 mb-2 font-bold group-hover:underline">
                {blog.title}
              </h3>
              <p className="text-dark-700 my-2 text-base leading-6">
                {blog.meta_description}
              </p>
              <a
                aria-label={blog.title}
                className="text-teal-900 text-base leading-6 font-semibold "
                href={blog.url}
                target="_blank"
                rel="noreferrer"
              >
                <span>Read more </span>
                <i className="icon-open-in-new-window h-4"></i>
              </a>
            </article>
          ))
        : null}
    </>
  );
};

export default Blog;
